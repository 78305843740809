<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="isEdit"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label> Produto <span class="text-danger">*</span> </template>
        <template #default>
          <KTSelect
            v-model="form.idpip_produto"
            :options="form_options.produtos"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Discriminação <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-form-input
            v-model="form.discriminacao"
            placeholder="Digite a discriminação"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Unidade de Medida <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-form-input
            size="lg"
            v-model="form.nome_unidade_medida"
            placeholder="Digite a unidade de medida"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>

      <div
        class="accordion"
        role="tablist"
        v-if="form.custos && form.custos.length"
      >
        <b-card
          no-body
          class="mb-1"
          v-for="(item, index) in form.custos"
          :key="'ano-' + item.ano"
        >
          <b-card-header
            header-tag="header"
            class="p-3 d-flex justify-content-between align-items-center"
            role="tab"
            :style="{ cursor: 'auto' }"
          >
            <b v-if="finalizado">Dados do Ano {{ item.ano }}:</b>
            <b v-else>Informar dados do Ano {{ item.ano }}:</b>
            <b-button
              v-b-toggle="'accordion-' + item.ano"
              variant="secondary"
              size="sm"
            >
              <span v-if="!accordion[index]">
                <i class="fa fa-angle-down"></i> Abrir
              </span>
              <span v-else> <i class="fa fa-angle-up"></i> Fechar </span>
            </b-button>
          </b-card-header>
          <b-collapse
            :id="'accordion-' + item.ano"
            accordion="my-accordion"
            role="tabpanel"
            v-model="accordion[index]"
          >
            <b-card-body>
              <div class="d-flex justify-content-between">
                <p class="font-weight-bold font-size-lg mb-0" v-if="finalizado">
                  Custos de produção por mês:
                </p>
                <p class="font-weight-bold font-size-lg mb-0" v-else>
                  Informe abaixo os custos de produção por mês:
                </p>
                <b-button
                  variant="secondary"
                  size="sm"
                  v-if="!finalizado && index > 0"
                  @click="repetirValores(index)"
                >
                  Repetir valores do ano anterior
                </b-button>
              </div>
              <b-row>
                <b-col sm="6" md="3" v-for="mes in 12" :key="'col-' + mes">
                  <b-card no-body class="p-3 mt-8">
                    <p class="font-weight-bold">Mês {{ mes }}</p>
                    <b-form-group label-size="lg">
                      <template #label>
                        Quantidade <span class="text-danger">*</span>
                      </template>
                      <template #default>
                        <KTInputNumeric
                          v-model="form.custos[index]['quantidade_mes_' + mes]"
                          :precision="2"
                          autocomplete="off"
                          :disabled="finalizado"
                        />
                      </template>
                    </b-form-group>
                    <b-form-group label-size="lg" class="mb-0">
                      <template #label>
                        Preço Unitário <span class="text-danger">*</span>
                      </template>
                      <template #default>
                        <b-input-group :prepend="simbolo_moeda">
                          <KTInputNumeric
                            v-model="
                              form.custos[index]['preco_unitario_mes_' + mes]
                            "
                            :precision="2"
                            autocomplete="off"
                            :disabled="finalizado"
                          />
                        </b-input-group>
                      </template>
                    </b-form-group>
                  </b-card>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";
import MsgService from "@/core/services/msg.service.js";
import CommonDataService from "@/core/services/commondata.service";

export default {
  name: "PipCustoProducaoForm",
  props: {
    idplano_investimento: {
      required: true
    },
    idpip_custo_producao: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-donate",
        title: "Custo de Produção",
        description: `Selecione o produto, discrimine o custo de produção envolvido,
          a unidade de medida e em seguida a quantidade e o respectivo preço. Você
          deve preencher esses valores mês a mês, de forma criteriosa, para cada ano.
          Caso tenha certeza que esses valores se repetem nos anos seguintes, utilize
          o botão Repetir valores do ano anterior.`
      },
      form: {
        idpip_produto: null,
        nome_unidade_medida: null,
        discriminacao: null,
        custos: []
      },
      form_options: {
        produtos: []
      },
      anos: 0,
      simbolo_moeda: null,
      accordion: [true],
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/custo_producao`;
    },
    manual() {
      return {
        session: "custo_producao",
        tipo: this.tipo_plano_investimento
      };
    },
    isEdit() {
      return !!this.idpip_custo_producao;
    },
    parentRoute() {
      return {
        name: "pip_custo_producao_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [
        this.Api.GetData(this.idpip_custo_producao),
        CommonDataService.getProduto(this.idplano_investimento)
      ];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;
        const R_PRODUTO = 1;

        this.anos = res[R_GETDATA].anos;
        this.simbolo_moeda = res[R_GETDATA].simbolo_moeda;

        this.form.idpip_produto = res[R_GETDATA].idpip_produto ?? null;
        this.form.discriminacao = res[R_GETDATA].discriminacao ?? null;
        this.form.nome_unidade_medida =
          res[R_GETDATA].nome_unidade_medida ?? null;

        this.form_options.produtos = res[R_PRODUTO] ?? [];

        if (
          res[R_GETDATA].custo_producao &&
          res[R_GETDATA].custo_producao.length
        ) {
          this.form.custos = res[R_GETDATA].custo_producao;
        } else {
          this.form.custos = [];
          for (let i = 1; i <= this.anos; i++) {
            let temp = {
              idpip_custo_producao: this.idpip_custo_producao,
              ano: i
            };
            for (let i = 1; i <= 12; i++) {
              temp["quantidade_mes_" + i] = null;
              temp["preco_unitario_mes_" + i] = null;
            }

            this.form.custos.push(temp);
          }
        }
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      if (this.validaCampos()) {
        this.ApiSubmit(this.idpip_custo_producao, this.form);
      }
    },
    repetirValores(indexAtual) {
      for (let i = 1; i <= 12; i++) {
        this.form.custos[indexAtual]["quantidade_mes_" + i] =
          this.form.custos[indexAtual - 1]["quantidade_mes_" + i];
        this.form.custos[indexAtual]["preco_unitario_mes_" + i] =
          this.form.custos[indexAtual - 1]["preco_unitario_mes_" + i];
      }
    },
    validaCampos() {
      let isValid = true;
      let errorAno = "";
      let errorMes = "";

      for (const item of this.form.custos) {
        for (var [key, value] of Object.entries(item)) {
          if (
            key.includes("quantidade_mes_") &&
            (value === null || typeof value == "undefined")
          ) {
            errorMes = key.replace("quantidade_mes_", "");
            errorAno = item.ano;
            isValid = false;
            break;
          }
          if (
            key.includes("preco_unitario_mes_") &&
            (value === null || typeof value == "undefined")
          ) {
            errorMes = key.replace("preco_unitario_mes_", "");
            errorAno = item.ano;
            isValid = false;
            break;
          }
        }

        if (!isValid) {
          break;
        }
      }

      if (!isValid) {
        MsgService.show({
          title: "Erro no preenchimento de dados",
          description: `Informe o valor do mês ${errorMes} no ano ${errorAno}.`,
          type: "error"
        });
        return false;
      }
      return true;
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
